import styled from '@emotion/styled'
import { Accordion } from 'app/components/Common/Accordion'
import { Props as ItemProps } from 'app/components/Common/Accordion/item'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

import { FlexBox } from '../Layout/FlexBox'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const BlockAccordion = memo(function BlockAccordion({
  items,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <TitleWrapper dial={4} row>
          <StyledFadeInUp delay={0.1}>
            <img src="/icon-shape-02.svg" alt="shape-02" />
          </StyledFadeInUp>
          <StyledFadeInUp delay={0.15}>
            <img src="/icon-shape-01.svg" alt="shape-01" />
          </StyledFadeInUp>
          <Title text={title} />
        </TitleWrapper>
      ) : null}
      {items ? <Accordion items={items} /> : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 8vw 0;
  padding: 0 10vw;

  @media (max-width: 991px) {
    margin: 0;
    padding: 0 30px;
  }
`

const Title = styled(AnimatedTitle)`
  position: relative;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.77vw;
  line-height: 1.15;

  @media (max-width: 991px) {
    font-size: 28px;
    background-size: 20px auto;
    margin-top: 0.75rem;
    width: 100%;
  }
`

const StyledFadeInUp = styled(FadeInUp)`
  display: inline-block;
`

const TitleWrapper = styled(FlexBox)`
  margin-bottom: 2.08vw;
  position: relative;

  img {
    height: 2.5vw;
    width: auto;
    margin-right: 2.5vw;
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;
    flex-wrap: wrap;

    img {
      height: 20px;
      margin-right: 15px;
    }
  }
`
